/* eslint-disable import/prefer-default-export */
import { MAKE_MODEL_APPROVAL_STATUS_BADGE as BADGE } from './constants';

export const getMakeModelStatusBadgeConfig = (makeModelApproval) => {
  const status = makeModelApproval?.fleetApprovalStatus;
  if (!status || !BADGE[status]) return null;
  return BADGE[status];
};

export const getMakeModelStatusBadge = (status) => {
  if (!status || !BADGE[status]) return null;
  return BADGE[status];
};

export const getApprovalStatus = (data) => {
  // Check if makeModelApproval.fleetApprovalStatus is not null
  if (
    data?.makeModelApproval &&
    data?.makeModelApproval?.fleetApprovalStatus !== null
  ) {
    return data?.makeModelApproval?.fleetApprovalStatus;
  }

  // If fleetApprovalStatus is null, check the conditions for make and model approval
  const makeApproved =
    data?.make?.approvalStatus === 'Approved' ||
    data?.make?.fleetStatus === 'Approved';
  const modelApproved =
    data?.model?.approvalStatus === 'Approved' ||
    data?.model?.fleetStatus === 'Approved';

  // Return the approval status based on the combination
  if (makeApproved && modelApproved) {
    return 'Approved';
  }
  return 'Rejected';
};
