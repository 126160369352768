import React, {
  createContext,
  useContext,
  useReducer,
  useState,
  useCallback,
} from 'react';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import PropTypes from 'prop-types';
import {
  makesFetchOptionsPromise,
  modelsFetchOptionsPromise,
} from '../../../fvs/requirements/components/make-model/api-helpers';

export const BidMakeAndModelContext = createContext({});

const initialState = {
  makeCode: '',
  makeName: '',
  modelCode: '',
  modelName: '',
  chassisMakeCode: '',
  chassisMakeName: '',
  chassisModelCode: '',
  chassisModelName: '',
  modelYear: '',
  tags: { value: '' },
  makeCustomKeyword: '',
  modelCustomKeyword: '',
  chassisMakeCustomKeyword: '',
  chassisModelCustomKeyword: '',
  isSubmitted: false,
  isClearedTypeAhead: false,
  status: null,
};

const FvsPreBidReducer = (state, action) => {
  switch (action.type) {
    case 'SET_MAKE': {
      return {
        ...state,
        makeCode: action.payload?.makeCode || '',
        makeName: action.payload?.makeName || '',
      };
    }
    case 'SET_MODEL': {
      return {
        ...state,
        modelCode: action.payload?.modelCode || '',
        modelName: action.payload?.modelName || '',
      };
    }
    case 'SET_CHASSIS_MAKE': {
      return {
        ...state,
        chassisMakeCode: action.payload?.makeCode || '',
        chassisMakeName: action.payload?.makeName || '',
      };
    }
    case 'SET_CHASSIS_MODEL': {
      return {
        ...state,
        chassisModelCode: action.payload?.modelCode || '',
        chassisModelName: action.payload?.modelName || '',
      };
    }
    case 'SET_MODEL_YEAR': {
      return { ...state, modelYear: action.payload || '' };
    }

    case 'SET_MAKE_CUSTOM_KEYWORD': {
      return { ...state, makeCustomKeyword: action.payload };
    }
    case 'SET_MODEL_CUSTOM_KEYWORD': {
      return { ...state, modelCustomKeyword: action.payload };
    }
    case 'SET_CHASSIS_MAKE_CUSTOM_KEYWORD': {
      return { ...state, chassisMakeCustomKeyword: action.payload };
    }
    case 'SET_CHASSIS_MODEL_CUSTOM_KEYWORD': {
      return { ...state, chassisModelCustomKeyword: action.payload };
    }
    case 'SET_IS_SUBMITTED': {
      return { ...state, isSubmitted: action.payload };
    }
    case 'SET_TYPE_AHEAD_IS_CLEARED': {
      return { ...state, isClearedTypeAhead: action.payload };
    }
    case 'SET_SELECTED_ITEM': {
      return { ...state, selectedMakeModelItem: { ...action.payload } };
    }
    case 'SET_STATUS': {
      return { ...state, status: action.payload };
    }
    // case 'RESET': {
    //   return { ...initialState };
    // }
    // use RESET to clear bodyMakeInputValue, bodyModelInputValue, chassisMakeInputValue, chassisModelInputValue, modelYear
    case 'RESET': {
      return {
        ...initialState,
        bodyMakeInputValue: '',
        bodyModelInputValue: '',
        chassisMakeInputValue: '',
        chassisModelInputValue: '',
        modelYear: '',
      };
    }

    default: {
      return state;
    }
  }
};

function BidsMakeAndModelProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(FvsPreBidReducer, initialState, () => {
    return initialState;
  });

  // -------------------------- borrowed from FVS --------------------------
  const { getToken } = useCurrentUser();

  const fetchMakes = useCallback(
    async (input, signal) => {
      const token = await getToken();
      return makesFetchOptionsPromise(input, token, signal);
    },
    [getToken],
  );

  const fetchModels = useCallback(
    async (input, makeCode, signal) => {
      const token = await getToken();
      return modelsFetchOptionsPromise(input, makeCode, token, signal);
    },
    [getToken],
  );

  const [hasChassis, setHasChassis] = useState(
    state.tags?.value?.includes('REQ_CHASSIS_MOD'),
  );

  const [modelYear, setModelYear] = useState();
  const [makeModelApprovalStatus, setMakeModelApprovalStatus] = useState();

  const bodyMakeInitialState = initialState?.bodyMake
    ? {
        label: initialState?.bodyMake?.makeName,
        value: initialState?.bodyMake?.makeCode,
      }
    : undefined;

  const [bodyMake, setBodyMake] = useState(bodyMakeInitialState);
  const [bodyMakeInputValue, setBodyMakeInputValue] = useState('');
  const [bodyMakeOptions, setBodyMakeOptions] = useState();

  const bodyModelInitialState = initialState?.bodyModel
    ? {
        label: initialState?.bodyModel?.modelName,
        value: initialState?.bodyModel?.modelCode,
      }
    : undefined;

  const [bodyModel, setBodyModel] = useState(bodyModelInitialState);
  const [bodyModelInputValue, setBodyModelInputValue] = useState('');
  const [bodyModelOptions, setBodyModelOptions] = useState();

  const chassisMakeInitialState = initialState?.chassisMake
    ? {
        label: initialState?.chassisMake?.makeName,
        value: initialState?.chassisMake?.makeCode,
      }
    : undefined;

  const [chassisMake, setChassisMake] = useState(chassisMakeInitialState);
  const [chassisMakeInputValue, setChassisMakeInputValue] = useState('');
  const [chassisMakeOptions, setChassisMakeOptions] = useState();

  const chassisModelInitialState = initialState?.chassisModel
    ? {
        label: initialState?.chassisModel?.modelName,
        value: initialState?.chassisModel?.modelCode,
      }
    : undefined;

  const [chassisModel, setChassisModel] = useState(chassisModelInitialState);
  const [chassisModelInputValue, setChassisModelInputValue] = useState('');
  const [chassisModelOptions, setChassisModelOptions] = useState();

  const [createError] = useState();

  const [dirtyFields, setDirtyFields] = useState([]);
  const getErrors = useCallback(() => {
    const requiredMsg = 'This is a required field';
    const errors = [];

    if (!bodyMake) {
      if (!bodyMakeInputValue) {
        errors.push({
          field: 'bodyMake',
          name: 'Body make',
          message: requiredMsg,
        });
      }
    }

    if (!bodyModel) {
      if (!bodyModelInputValue) {
        errors.push({
          field: 'bodyModel',
          name: 'Body model',
          message: requiredMsg,
        });
      }
    }

    if (hasChassis) {
      if (!chassisMake) {
        if (!chassisMakeInputValue) {
          errors.push({
            field: 'chassisMake',
            name: 'Chassis make',
            message: requiredMsg,
          });
        }
      }

      if (!chassisModel) {
        if (!chassisModelInputValue) {
          errors.push({
            field: 'chassisModel',
            name: 'Chassis model',
            message: requiredMsg,
          });
        }
      }
    }

    if (!modelYear) {
      errors.push({
        field: 'modelYear',
        name: 'Model year',
        message: requiredMsg,
      });
    }

    return errors;
  }, [
    bodyMake,
    bodyMakeInputValue,
    bodyModel,
    bodyModelInputValue,
    chassisMake,
    chassisMakeInputValue,
    chassisModel,
    chassisModelInputValue,
    modelYear,
    hasChassis,
  ]);
  // -------------------------- end borrowed from FVS --------------------------

  const reset = () => {
    setBodyMakeInputValue('');
    setBodyModelInputValue('');
    setChassisMakeInputValue('');
    setChassisModelInputValue('');
    setModelYear('');
  };

  const setAddMakeModelContext = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  return (
    <BidMakeAndModelContext.Provider
      value={{
        ...state,
        setAddMakeModelContext,

        hasChassis,
        setHasChassis,

        bodyMake,
        setBodyMake,
        bodyMakeInputValue,
        setBodyMakeInputValue,
        bodyMakeOptions,
        setBodyMakeOptions,

        bodyModel,
        setBodyModel,
        bodyModelInputValue,
        setBodyModelInputValue,
        bodyModelOptions,
        setBodyModelOptions,

        chassisMake,
        setChassisMake,
        chassisMakeInputValue,
        setChassisMakeInputValue,
        chassisMakeOptions,
        setChassisMakeOptions,

        chassisModel,
        setChassisModel,
        chassisModelInputValue,
        setChassisModelInputValue,
        chassisModelOptions,
        setChassisModelOptions,

        modelYear,
        setModelYear,

        makeModelApprovalStatus,
        setMakeModelApprovalStatus,

        fetchMakes,
        fetchModels,

        getErrors,
        dirtyFields,
        setDirtyFields,

        createError,

        reset,

        ...props,
      }}
    >
      {children}
    </BidMakeAndModelContext.Provider>
  );
}

export default BidsMakeAndModelProvider;

BidsMakeAndModelProvider.defaultProps = {};

BidsMakeAndModelProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useBidMakeAndModelContext = () =>
  useContext(BidMakeAndModelContext);
