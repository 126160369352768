// eslint-disable-next-line
export const TABS = [
  { heading: 'Contract Line', tabKey: 'updateLineTabData' },
  { heading: 'Contract Line detail', tabKey: 'updateDetailTabData' },
  { heading: 'Minimum requirements', tabKey: 'MinReq' },
  { heading: 'Optional equipment', tabKey: 'OptEq' },
  { heading: 'Engine and fuel', tabKey: 'Engine' },
  { heading: 'Colors', tabKey: 'colorTabData' },
  { heading: 'Documentation', tabKey: 'Documentation' }, // saved separately
];
