import { string2number } from './ec-helpers';

export const getLineTabData = (lineTabData, lineItem) => {
  if (!lineTabData) {
    return {};
  }

  const input = {};

  if (lineTabData?.makeCode || lineTabData.make?.makeName) {
    input.makeName = {
      newValue: lineTabData.make?.makeName,
      oldValue: lineItem.make?.makeName,
    };
  }

  if (lineTabData?.modelCode || lineTabData.model?.modelName) {
    input.modelName = {
      newValue: lineTabData.model?.modelName,
      oldValue: lineItem.model?.modelName,
    };
  }

  if (lineTabData?.modelYear) {
    input.modelYear = {
      newValue: String(lineTabData.modelYear),
      oldValue: String(lineItem.modelYear),
    };
  }

  if (lineTabData?.chassisMakeCode || lineTabData.chassisMake?.makeName) {
    input.chassisMakeName = {
      newValue: lineTabData.chassisMake?.makeName,
      oldValue: lineItem.chassisMake?.makeName,
    };
  }

  if (lineTabData?.chassisModelCode || lineTabData.chassisModel?.modelName) {
    input.chassisModelName = {
      newValue: lineTabData.chassisModel?.modelName,
      oldValue: lineItem.chassisModel?.modelName,
    };
  }

  if ('orderStartDate' in lineTabData) {
    input.orderStartDate = {
      newValue: lineTabData.orderStartDate,
      oldValue: lineItem.orderStartDate,
    };
  }

  if ('orderEndDate' in lineTabData) {
    input.orderEndDate = {
      newValue: lineTabData.orderEndDate,
      oldValue: lineItem.orderEndDate,
    };
  }

  if ('additionalInformation' in lineTabData) {
    input.additionalInformation = {
      newValue: lineTabData.additionalInformation,
      oldValue: lineItem.additionalInformation,
    };
  }

  if ('quantity' in lineTabData) {
    input.quantity = {
      newValue: String(lineTabData.quantity),
      oldValue: String(lineItem.quantity),
    };
  }

  if ('isPurchaseAvailable' in lineTabData) {
    input.isPurchaseAvailable = {
      newValue: lineTabData.isPurchaseAvailable,
      oldValue: String(lineItem.isPurchaseAvailable),
    };
  }

  if ('shipmentOrDelivery' in lineTabData) {
    input.shipmentOrDelivery = {
      newValue: lineTabData.shipmentOrDelivery,
      oldValue: lineItem.shipmentOrDelivery,
    };
  }

  if ('deliveryDate' in lineTabData) {
    input.deliveryDate = {
      newValue: lineTabData.deliveryDate,
      oldValue: lineItem.deliveryDate,
    };
  }

  if ('vin' in lineTabData) {
    input.vin = {
      newValue: lineTabData.vin,
      oldValue: lineItem.vin,
    };
  }

  if ('coFirstName' in lineTabData) {
    input.coFirstName = {
      newValue: lineTabData.coFirstName,
      oldValue: lineItem.coFirstName,
    };
  }

  if ('coLastName' in lineTabData) {
    input.coLastName = {
      newValue: lineTabData.coLastName,
      oldValue: lineItem.coLastName,
    };
  }

  if ('coEmail' in lineTabData) {
    input.coEmail = {
      newValue: lineTabData.coEmail,
      oldValue: lineItem.coEmail,
    };
  }

  if ('manufacturerModelNumber' in lineTabData) {
    input.manufacturerModelNumber = {
      newValue: lineTabData.manufacturerModelNumber,
      oldValue: lineItem.manufacturerModelNumber,
    };
  }

  if ('isDirect' in lineTabData) {
    input.isDirect = {
      newValue: String(lineTabData.isDirect),
      oldValue: String(lineItem.isDirect),
    };
  }

  const financialInfo = lineTabData.contractLineFinancialRef || {};
  if (Object.keys(financialInfo).length > 0) {
    input.contractLineFinancialRef = {};
    const originalFinancialInfo = lineItem.contractLineFinancialRef || {};
    if (financialInfo?.unitPrice) {
      input.contractLineFinancialRef.unitPrice = {
        newValue: String(financialInfo.unitPrice),
        oldValue: String(originalFinancialInfo.unitPrice),
      };
    }

    if (financialInfo?.invoicePrice) {
      input.contractLineFinancialRef.invoicePrice = {
        newValue: String(financialInfo.invoicePrice),
        oldValue: String(originalFinancialInfo.invoicePrice),
      };
    }

    if (financialInfo?.msrp) {
      input.contractLineFinancialRef.msrp = {
        newValue: String(financialInfo.msrp),
        oldValue: String(originalFinancialInfo.msrp),
      };
    }

    if (financialInfo?.asePrice) {
      input.contractLineFinancialRef.asePrice = {
        newValue: String(financialInfo.asePrice),
        oldValue: String(originalFinancialInfo.asePrice),
      };
    }

    if ('destinationCharge' in financialInfo) {
      input.contractLineFinancialRef.destinationCharge = {
        newValue: String(financialInfo.destinationCharge),
        oldValue: String(originalFinancialInfo.destinationCharge),
      };
    }
  }

  return input;
};

export const getLineDetailTabData = (lineDetailTabData) => {
  const input = {};

  if (lineDetailTabData?.participant1122Prog !== null) {
    input.participant1122Prog = JSON.parse(
      lineDetailTabData.participant1122Prog,
    );
  }

  if (lineDetailTabData?.shipmentDays !== null) {
    input.shipmentDays = parseInt(lineDetailTabData.shipmentDays, 10);
  }

  if (lineDetailTabData?.shipmentJustification !== null) {
    input.shipmentJustification = lineDetailTabData.shipmentJustification;
  }

  if (lineDetailTabData?.associatedLocations !== null) {
    input.associatedLocations = lineDetailTabData.associatedLocations?.map(
      (l) => {
        return {
          id: l.id,
          vendorAssemblyPointId: l.vendorAssemblyPointId,
          vendorInspectionPointId: l.vendorInspectionPointId,
          percentageOfDomContent: l.percentageOfDomContent,
        };
      },
    );
  }

  if (lineDetailTabData?.clarifications !== null) {
    input.clarifications = lineDetailTabData.clarifications?.map((c) => {
      const toReturn = {
        id: parseInt(c.id, 10),
        clarification: c.clarification,
        clarificationType: c.clarificationType,
      };
      if (c.id?.startsWith('local_')) {
        delete toReturn.id;
      }

      return toReturn;
    });
  }

  return input;
};

// helpers to get colors input for update
const getFieldValue = (field, obj) =>
  field.converter ? field.converter(obj[field.name]) : obj[field.name] || '';

const ColorFields = [
  { apiName: 'description', name: 'vendorColorName' },
  {
    apiName: 'gsaColorCodeId',
    name: 'gsaColorCodeId',
    converter: string2number,
  },
  { apiName: 'standardPremiumCode', name: 'standardPremiumCode' },
  {
    apiName: 'price',
    name: 'price',
    converter: string2number,
  },
];
const getColorInput = (prevColor, color) => {
  return ColorFields.reduce((inputObj, field) => {
    const oldValue = getFieldValue(field, prevColor);
    const newValue = getFieldValue(field, color);
    if (oldValue !== newValue)
      return { ...inputObj, [field.apiName]: { oldValue, newValue } };
    return inputObj;
  }, {});
};
const getColorObj = (prevColor, color) => {
  // new or update
  if (color) {
    const input = getColorInput(prevColor ?? {}, color);
    if (Object.keys(input).length === 0) return undefined;
    return { colorCode: color.vendorColorCode, ...input };
  }
  // delete
  if (prevColor && !color) {
    const input = getColorInput(prevColor, {});
    return { colorCode: prevColor.vendorColorCode, isDeleted: true, ...input };
  }
  return undefined;
};

export const getColorsTabData = (prevColors, newColors) => {
  const colorsTabData = [];

  // new or update
  newColors.forEach((c) => {
    const prevC = prevColors.find(
      (pc) => pc.vendorColorCode === c.vendorColorCode,
    );
    const colorData = getColorObj(prevC, c);
    if (colorData) colorsTabData.push(colorData);
  });

  // delete
  prevColors.forEach((pc) => {
    if (!newColors.find((nc) => pc.vendorColorCode === nc.vendorColorCode))
      colorsTabData.push(getColorObj(pc, undefined));
  });

  if (!colorsTabData.length) return undefined;
  return colorsTabData;
};
