import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery, useMutation } from '@apollo/client';

import {
  GET_CONTRACT_LINE_BY_ID,
  GET_EQUIPMENT_OPTIONS,
  GET_GSA_CONSTANTS,
  SAVE_CONTRACT_LINE_BY_ID,
} from './query';
import { GET_VENDOR_LOCATIONS_BY_TYPES } from '../../../../utilities/feature-toggle/get-vendors-query';
import {
  processEquipmentCodesData,
  getOptionalEcList,
  getMinReqSaveObj,
  getOptEqSaveObj,
  // isEcTabReady,
  mapEngineDataToTable,
  processEngineItem,
  sortEngineECsFn,
  // isEngineTabReady,
  getEngineSaveObj,
} from '../helpers/ec-helpers';
import { TABS } from '../helpers/constants';
import {
  getLineDetailTabData,
  getLineTabData,
  getColorsTabData,
} from '../helpers/line-helpers';
import useDocumentationTab from './use-documentation-tab';

const useVendorLocations = (vendorId) => {
  const vendorLocationQueryResult = useQuery(GET_VENDOR_LOCATIONS_BY_TYPES, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      vendorId,
      locationTypeCodes: ['AsmPoint', 'InspPoint'],
    },
    skip: !vendorId,
  });

  return vendorLocationQueryResult;
};

export const ContractLineContext = createContext({});

export const ContractLineProvider = ({ children }) => {
  const { contractLineId } = useParams();

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const tabIndexFromQueryParams = parseInt(queryParams.tabIndex, 10);
  const initialTabIndex =
    location.state?.tabIndex || tabIndexFromQueryParams || 0;
  const [tabIndex, setTabIndex] = useState(initialTabIndex);
  const [alert, setAlert] = useState(null);

  const [gsaColors, setGsaColors] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [states, setStates] = useState([]);

  const [lineTabData, setLineTabData] = useState({});
  const [detailTabData, setDetailTabData] = useState({
    participant1122Prog: null,
    shipmentDays: null,
    shipmentJustification: null,
    associatedLocations: null,
    clarifications: null,
  });

  // min-req and opt-eq tabs
  const [standardECs, setStandardECs] = useState([]);
  const [standardECTable, setStandardECTable] = useState([]);
  const [optionalECs, setOptionalECs] = useState([]);
  const [optionalECTable, setOptionalECTable] = useState([]);

  const getStandardEC = ({ iCat, iData }) => standardECs[iCat].data[iData];
  const getOptionalEC = ({ iCat, iData }) => optionalECs[iCat].data[iData];

  const setEC = (
    setEcFn,
    setEcTableFn,
    { categoryCode, id },
    { data, table },
  ) => {
    const genMapFn = (update) => (item) => {
      if (item.id !== id) return item;
      return { ...item, ...update };
    };
    if (table)
      setEcTableFn((prevItems) =>
        prevItems.map((cat) => {
          if (cat.id === categoryCode) {
            const tableItem = cat.data.find((item) => item.id === id);
            if (table.isExpanded !== tableItem.isExpanded)
              return { ...cat, data: cat.data.map(genMapFn(table)) };
          }
          return cat;
        }),
      );
    if (data)
      setEcFn((prevItems) =>
        prevItems.map((cat) => {
          if (cat.id !== categoryCode) return cat;
          return { ...cat, data: cat.data.map(genMapFn(data)) };
        }),
      );
  };
  const setStandardEC = (original, updates) =>
    setEC(setStandardECs, setStandardECTable, original, updates);
  const setOptionalEC = (original, updates) =>
    setEC(setOptionalECs, setOptionalECTable, original, updates);

  // engine-and-fuel tab
  const [engineData, setEngineData] = useState([]);
  const [engineTable, setEngineTable] = useState([]);
  const setEngine = (id, { data, table }) => {
    if (table)
      setEngineTable((prevItems) =>
        prevItems.map((line) => {
          if (line.id !== id) return line;
          return { ...line, ...table };
        }),
      );
    if (data)
      setEngineData((prevItems) =>
        prevItems.map((line) => {
          if (line.id !== id) return line;
          return { ...line, ...data };
        }),
      );
  };
  const deleteEngineLine = (id) => {
    setEngine(id, { data: { isDeleted: true }, table: { isDeleted: true } });
  };
  const addEngineLine = (ecItem, siTags) => {
    const deletedEngine = engineTable.find((line) => line.id === ecItem.id);
    if (deletedEngine)
      setEngine(deletedEngine.id, {
        data: { isDeleted: false },
        table: { isDeleted: false },
      });
    else {
      const newItem = processEngineItem(ecItem, [], true, [], siTags);
      setEngineData((prev) => {
        const newEngines = [...prev];
        newEngines.unshift(newItem);
        newEngines.sort(sortEngineECsFn);
        setEngineTable(newEngines.map(mapEngineDataToTable));
        return newEngines;
      });
    }
  };

  const [colorsTabData, setColorsTabData] = useState(null);

  const {
    photos,
    documents,
    setDocuments,
    setPhotos,
    loadContractLineAttachments,
    getSignedUrl,
    setSignedUrl,
  } = useDocumentationTab(contractLineId);

  // fetch contract line data
  const getContractLineQueryResult = useQuery(GET_CONTRACT_LINE_BY_ID, {
    variables: {
      contractLineId: parseFloat(contractLineId),
    },
    skip: !contractLineId,
    onCompleted: (data) => {
      const docs = data?.getContractLineTemplateById?.contractLineAttachments?.filter(
        (d) => d.documentType === 'D',
      );
      const pics = data?.getContractLineTemplateById?.contractLineAttachments?.filter(
        (d) => d.documentType === 'I',
      );
      setDocuments(docs);
      setPhotos(pics);
    },
  });

  const {
    data: contractLineData,
    called,
    loading: clLoading,
    error: contractLineError,
    refetch,
  } = getContractLineQueryResult;

  const contractLine = contractLineData?.getContractLineTemplateById || {};
  const vendorData = contractLine?.contractHeader?.vendor;
  const vendorId = vendorData?.id;
  const isAwardedInFleet = Boolean(contractLine?.contractHeader?.fleetAwarded);
  const requiresChassis = Boolean(
    contractLine?.standardItem?.tags?.value?.includes?.('REQ_CHASSIS_MOD'),
  );

  const { data: siecData, loading: siecLoading } = useQuery(
    GET_EQUIPMENT_OPTIONS,
    {
      fetchPolicy: 'cache-and-network',
      skip: !contractLine.standardItemId,
      variables: {
        standardItemId: contractLine.standardItemId,
        getActive: true,
      },
      notifyOnNetworkStatusChange: true,
    },
  );
  const { loading: constLoading } = useQuery(GET_GSA_CONSTANTS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setFuelTypes(data.fuelTypes);
      setGsaColors(data.gsaColors);
      setStates(data.states);
    },
    onError: () => {},
  });

  useEffect(() => {
    const ecs = siecData?.getEquipmentOptions;
    if (!contractLine || !fuelTypes || !ecs?.length) return;

    const procData = processEquipmentCodesData(
      ecs,
      contractLine,
      isAwardedInFleet,
      fuelTypes,
    );
    const mapDataFn = ({ id, data }) => ({ id, data });
    const mapTableFn = ({ data, tableData, ...catData }) => ({
      ...catData,
      data: tableData,
    });
    setStandardECs(procData.standard.map(mapDataFn));
    setStandardECTable(procData.standard.map(mapTableFn));
    setOptionalECs(procData.optional.map(mapDataFn));
    setOptionalECTable(procData.optional.map(mapTableFn));
    setEngineData(procData.engines);
    setEngineTable(procData.engines.map(mapEngineDataToTable));
  }, [contractLine, siecData, isAwardedInFleet, fuelTypes]);

  const vendorLocQueryResult = useVendorLocations(vendorId);
  const vendorLocations = vendorLocQueryResult?.data?.getVendorLocationsByTypes;

  const loading = clLoading || siecLoading || constLoading;

  const [saveContractLineMutation, { loading: saving }] = useMutation(
    SAVE_CONTRACT_LINE_BY_ID,
    {
      onCompleted: () => {
        setAlert({
          type: 'success',
          message: 'Contract line saved successfully',
        });
        refetch();
      },
      onError: (error) => {
        setAlert({
          type: 'error',
          message: `An error occurred while saving the contract line: ${error?.message}`,
        });
      },
    },
  );

  const saveContractLineInput = useCallback(() => {
    const notReadyTabs = [];
    // if (!isEcTabReady(standardECs)) notReadyTabs.push(TABS[2].heading); // MinReq
    // if (!isEcTabReady(optionalECs)) notReadyTabs.push(TABS[3].heading); // OptEq
    // if (!isEngineTabReady(engineData)) notReadyTabs.push(TABS[4].heading); // Engine&fuel

    if (notReadyTabs.length) {
      setAlert({
        type: 'error',
        message: `Unable to save. Please complete the following tab(s): ${notReadyTabs.join(
          ', ',
        )}.`,
      });
      return;
    }
    setAlert(null);

    // prepare save api payload
    const contractLineInput = {};

    const purchaseType =
      contractLine?.contractHeader?.solicitation?.purchaseTypeCode;
    if (purchaseType !== 'S' && purchaseType !== 'Y') {
      if (
        (!lineTabData?.coFirstName || !lineTabData?.coLastName) &&
        (!contractLine?.coFirstName || !contractLine?.coLastName)
      ) {
        setAlert({
          type: 'error',
          message: 'Please enter the Contracting Officer first and last name.',
        });
        return;
      }
    }
    if (Object.keys(lineTabData).length > 0) {
      contractLineInput.lineTabData = getLineTabData(lineTabData, contractLine);
    }

    const input = getLineDetailTabData(detailTabData);
    if (Object.keys(input).length > 0) {
      contractLineInput.lineDetailTabData = getLineDetailTabData(detailTabData);
    }

    contractLineInput[TABS[2].tabKey] = getMinReqSaveObj(
      standardECs,
      contractLineId,
    );
    const ecList = getOptionalEcList(optionalECs);
    contractLineInput[TABS[3].tabKey] = getOptEqSaveObj(
      optionalECs,
      contractLineId,
      ecList,
    );
    contractLineInput[TABS[4].tabKey] = getEngineSaveObj(
      engineData,
      contractLineId,
      ecList,
    );
    contractLineInput[TABS[5].tabKey] = getColorsTabData(
      contractLine.modelColors ?? [],
      colorsTabData ?? [],
    );

    if (Object.values(contractLineInput).filter((v) => v).length > 0)
      saveContractLineMutation({
        variables: {
          contractLineId: parseFloat(contractLineId),
          contractLineInput,
        },
      });
  }, [
    contractLine,
    lineTabData,
    detailTabData,
    standardECs,
    optionalECs,
    engineData,
    contractLineId,
    colorsTabData,
    isAwardedInFleet,
  ]);

  const contextValue = {
    contractLineId,
    contractLine,
    isAwardedInFleet,
    contractLineData,
    loading,
    saving,
    contractLineError,
    vendorData,
    requiresChassis,

    gsaColors,
    fuelTypes,
    states,

    vendorLocQueryResult,
    vendorLocations,

    saveContractLineInput,

    alert,
    setAlert,
    called,
    refetch,

    lineTabData,
    setLineTabData,

    detailTabData,
    setDetailTabData,

    standardECs,
    standardECTable,
    setStandardECTable,
    getStandardEC,
    setStandardEC,

    optionalECs,
    optionalECTable,
    setOptionalECTable,
    getOptionalEC,
    setOptionalEC,

    engineData,
    engineTable,
    setEngine,
    deleteEngineLine,
    addEngineLine,

    photos,
    documents,
    loadContractLineAttachments,
    getSignedUrl,
    setSignedUrl,

    colorsTabData,
    setColorsTabData,

    tabIndex,
    setTabIndex,
  };

  return (
    <ContractLineContext.Provider value={contextValue}>
      {children}
    </ContractLineContext.Provider>
  );
};

ContractLineProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useContractLine = () => useContext(ContractLineContext);
